import { put, call, select } from 'redux-saga/effects'
import path from 'ramda/es/path'
import pathOr from 'ramda/es/pathOr'
import { push, goBack, replace } from 'connected-react-router'
import { stopSubmit } from 'redux-form'
import { ToastsStore } from 'react-toasts'
import { show } from 'redux-modal'

import { userActions } from '../actions/UserActions'
import { profileActions } from '../actions/ProfileActions'
import { contentActions } from '../actions/ContentActions'
import profileSelectors from '../selectors/ProfileSelectors'
import userSelectors from '../selectors/UserSelectors'
import contentSelectors from '../selectors/ContentSelectors'
import i18n from 'services/i18n'

import { handleToastFocus } from 'helpers/handleToastFocus'
import {
  PROFILE_OVERVIEW,
  ABOUT_ME,
  SWIPE,
  UPDATE_BASIC_INFO
} from 'constants/urls'
import API from 'services/api'

import s from 'layouts/SignedInLayout/Toast.module.scss'

export function* setVerifyInfo(action) {
  const { responseSuccess, responseFailure, data } = action
  try {
    const {
      firstName,
      lastName,
      bio,
      preferredEmail,
      preferredFirstName,
      preferredLastName,
      gender,
      email,
      phoneNumber,
      questions: feedback,
      hidden,
      personalPronoun
    } = data
    const currentPathname = yield select(state => path(['router', 'location', 'pathname'], state))
    const isGenderUpdateMode = window?.history?.state?.state?.genderUpdateMode
    const isUpdateMode = currentPathname === UPDATE_BASIC_INFO
    const membershipId = yield select(userSelectors.membershipIdSelector)
    const question = yield select(contentSelectors.feedbackQuestionSelector)
    const preferredFilled = yield select(contentSelectors.preferredFilledSelector)
    const accessToken = yield select(userSelectors.accessTokenSelector)

    const questions = question
      ? [{
        response: pathOr(0, [0, 'response'], feedback),
        question_num: pathOr('1', ['question_num'], question),
        type: pathOr('select', ['type'], question)
      }]
      : []

    const { res, err } = yield call(API.setVerifyInfo, accessToken, membershipId, {
      hidden,
      first_name: firstName,
      last_name: lastName,
      bio,
      gender: gender?.id,
      phoneNumber: `+${phoneNumber}`,
      email: (email || '').trim(),
      questions,
      personalPronoun,
      preferred_email: preferredEmail,
      preferred_first_name: preferredFilled ? preferredFirstName : '',
      preferred_last_name: preferredFilled ? preferredLastName : ''
    })

    if (res) {
      const userData = {
        firstName,
        lastName,
        gender,
        email,
        hidden
      }
      yield put(responseSuccess(userData))
      yield put(userActions.updateRegisterData(userData))
      yield put(profileActions.updateUserData({ first_name: firstName, last_name: lastName }))
      yield put(contentActions.getVerifyInfoContent())
      const aboutMeHasAllAnswers = yield select(profileSelectors.aboutMeHasAllAnswersSelector)
      const areQuestionsSaved = yield select(userSelectors.areQuestionsSavedSelector)

      if (isUpdateMode || isGenderUpdateMode) {
        ToastsStore.success(i18n.t('default.updated'), 3000, s.toast)
        handleToastFocus()

        if (isGenderUpdateMode) {
          yield put(push(SWIPE))
        } else {
          yield put(goBack())
        }
      } else if(!aboutMeHasAllAnswers || !areQuestionsSaved) {
        console.log('Redirecting to About Me')
        yield put(replace(ABOUT_ME))
      } else {
        yield put(replace(PROFILE_OVERVIEW))
      }
    } else if (err.validation) {
      const errors = {}
      err.validation.map(({ title, error_text: error }) => {
        const titleLower = title.toLowerCase()
        if (titleLower.includes('email')) {
          errors.email = error
        } else if (titleLower.includes('first')) {
          errors.firstName = error
        } else if (titleLower.includes('last')) {
          errors.lastName = error
        } else if (titleLower.includes('phone number')) {
          errors.phoneNumber = error
        } else if (titleLower.includes('sex')) {
          errors.sex = error
          ToastsStore.error('Please select your gender', 3000, s.toast)
        } else if (error.includes('pronoun')) {
          errors.personalPronoun = i18n.t('verify.pronounError')
        }
      })
      ToastsStore.error('Update failed!', 3000, s.toast)
      handleToastFocus()
      yield put(stopSubmit('verifyInfo', errors))
      yield put(responseFailure())
    } else {
      yield put(responseFailure())
    }
  } catch (err) {
    yield put(responseFailure(err))
  }
}

export function* deleteAccount(action) {
  const { responseSuccess, responseFailure } = action

  try {
    const accessToken = yield select(userSelectors.accessTokenSelector)
    const { res } = yield call(API.deleteAccount, accessToken)

    if (res) {
      yield put(show(
        'infoModal',
        {
          logoutOnHide: true,
          label: i18n.t('profile.deletedTitle'),
        }
      ))
      yield put(responseSuccess())
    } else {
      yield put(responseFailure())
      ToastsStore.error('Something went wrong, try again later!', 3000, s.toast)
    }
  } catch (err) {
    yield put(responseFailure(err))
  }
}
