import { show } from 'redux-modal'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'

import userSelectors from 'redux/selectors/UserSelectors'
import chatSelectors from 'redux/selectors/ChatSelectors'
import roomSelectors from 'redux/selectors/RoomSelectors'
import routeSelectors from 'redux/selectors/RouteSelectors'
import swipeSelectors from 'redux/selectors/SwipeSelectors'
import communitySelectors from 'redux/selectors/CommunitySelectors'
import profileSelectors from 'redux/selectors/ProfileSelectors'

import { roomActions } from 'redux/actions/RoomActions'
import { userActions } from 'redux/actions/UserActions'
import { chatActions } from 'redux/actions/ChatActions'
import { profileActions } from 'redux/actions/ProfileActions'

import { NavigationSideMenu as View } from './NavigationSideMenu'

const mapStateToProps = createStructuredSelector({
  isError: swipeSelectors.isErrorSelector,
  initialSelection: routeSelectors.getRouteIndex,
  initialSelectionClosed: routeSelectors.getRouteIndexClosed,
  isAuthenticated: userSelectors.isAuthenticatedSelector,
  isRegistrationFlow: routeSelectors.isRegistrationFlow,
  isNetworkClosed: communitySelectors.isMatchingFinishedSelector,
  isNetworkPaused: profileSelectors.isNetworkPausedSelector,
  unreadMessagesCount: chatSelectors.totalUnreadCountSelector,
  incomingRequests: roomSelectors.incomingRequestsCountSelector,
})

const mapDispatchToProps = {
  showModal: show,
  resetStore: userActions.resetStore,
  logoutChat: chatActions.logoutChat,
  getMyRoom: roomActions.getRoomContent,
  getProfile: profileActions.getProfile,
  getProfileOverview: profileActions.getProfileOverview
}

export const NavigationSideMenu = connect(mapStateToProps, mapDispatchToProps)(withRouter(View))
