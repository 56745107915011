import { createReducer } from 'services/reduxHelpers'
import { userTypes } from '../actions/UserActions'

/* ------------- Initial State ------------- */

export const initialState = {
  auth: {
    accessToken: null,
    userId: null,
    membershipId: null
  },
  register: {
    networkId: null,
    roomTypeId: null,
    subnetworkId: null,
    unitTypeId: null,
    firstName: '',
    lastName: '',
    gender: '',
    email: '',
    feedback: 0,
    bio: '',
    bioEnabled: false
  },
  loadingStates: {
    networkId: false,
    roomTypeId: false,
    unitTypeId: false,
    passcode: false,
    verifyInfo: false
  }
}

/* ------------- Hookup Reducers To Types ------------- */

export default createReducer(initialState, {
  [userTypes.UPDATE_AUTH_DATA_ATTEMPT]: ({ auth }, { data }) => ({
    auth: {
      ...auth,
      ...data
    }
  }),
  [userTypes.UPDATE_REGISTER_DATA_ATTEMPT]: ({ register }, { data }) => ({
    register: {
      ...register,
      ...data
    }
  }),

  [userTypes.SET_USER_VERIFY_INFO_ATTEMPT]: ({ loadingStates }) => ({
    loadingStates: {
      ...loadingStates,
      verifyInfo: true
    }
  }),
  [userTypes.SET_USER_VERIFY_INFO_SUCCESS]: ({ loadingStates, register }, { data }) => ({
    loadingStates: {
      ...loadingStates,
      verifyInfo: false
    },
    register: {
      ...register,
      ...data
    }
  }),
  [userTypes.SET_USER_VERIFY_INFO_FAILURE]: ({ loadingStates }) => ({
    loadingStates: {
      ...loadingStates,
      verifyInfo: false
    }
  })
})
